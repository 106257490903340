import { Avatar, Button, Divider, Grid, Typography, TextField } from "@material-ui/core";
import timeIcon from "assets/icons/group-4.png";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import dayjs from "~/config/dayjs";
import Utils from "~/helpers/Utils";
import checkoutServices from "~/services/checkoutServices";
import useStyles from "./styles";
import { useMixpanel } from "react-mixpanel-browser";
import DialogAddCard from "../common/dialog/dialogAddCard";
import DialogPix from "../common/dialog/dialogPix";
import DialogDiscount from "../common/dialog/dialogDiscount";
import DialogPayConfirm from "../common/dialog/dialogPayConfirm";
import bookingTransactionService from "~/services/bookingTransactionService";
import _ from "lodash";
import ChatIcon from "@material-ui/icons/Chat";
import { useTranslation } from "react-i18next";
import ScheduleProgress from "../common/scheduleProgress/ScheduleProgress";
import { DialogBuilder } from "~/utils/DialogProvider";
import { useContext } from "react";
import dialogPayCod from "../common/dialog/dialogPayCod";
import businessService from "~/services/businessService";
import GoogleIcon from '@mui/icons-material/Google';
import PhoneIcon from '@material-ui/icons/Phone';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import SEO from "~/components/seo";

const Checkout = (props) => {
	const mixpanel = useMixpanel();
	const classes = useStyles();
	const observationTextField = useRef(null);
	const { business } = useSelector((store) => store.business);
	const { user } = useSelector((store) => store.auth);
	const me = JSON.parse(localStorage.getItem("@prit-web/meData"));
	const dialog = useContext(DialogBuilder);
	const { i18n, t } = useTranslation();
	const [contract, setContract] = useState();
	const [checkoutInformation, setCheckoutInformation] = useState(null);
	const [loading, setLoading] = useState(false);

	const [errorObservation, setErrorObservation] = useState(false);
	const [observation, setObservation] = useState("");
	const [characterCount, setCharacterCount] = useState(0);

	const [cardData, setCardData] = useState();
	const [pix, setPix] = useState();
	const [pixCode, setPixCode] = useState();
	const [showUpdateInfo, setShowUpdateInfo] = useState(false);
	const [offerAccepted, setOfferAccepted] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [showError, setShowError] = useState(false);

	const payload = JSON.parse(localStorage.getItem("@prit-web/checkoutPayload"));

	const services = props?.location?.state?.services || payload?.services || [];
	const questions = props?.location?.state?.questions || payload?.questions || [];
	const recipientId = props?.location?.state?.recipientId || payload?.recipientId;
	const coupon = props?.location?.state?.coupon || payload?.coupon;
	const bookingId = props?.location?.state?.bookingId || payload?.bookingId;
	const reschedule = props?.location?.state?.reschedule || payload?.reschedule;
	const postPayment = props?.location?.state?.postPayment || payload?.postPayment;

	const currency = business?.currency;

	const totalPricingType = Utils.getTotalPricingType(services);
	const couponCheck = localStorage.getItem("@prit-web/NoCoupon");

	const dispatch = useDispatch();

	useEffect(() => {
		if (props?.location?.state?.recipientId) {
			localStorage.setItem("@prit-web/checkoutPayload", JSON.stringify(props?.location?.state));
		}
		if (postPayment) {
			setCheckoutInformation(postPayment.checkoutInformation);
		} else {
			if (services?.length > 0) {
				let request = {
					recipient_id: recipientId,
					services: services.map((service) => ({
						date: dayjs(service.date).utc().add(dayjs(service.date).utcOffset(), "minute"),
						service_id: service.service_id,
						professional_id: service.professional_id,
						professional: {
							price: service.professional.price,
							pricing_type: service.professional.pricingType,
						},
					})),
				};
				if (couponCheck) {
					request.coupon_id = coupon?.coupon?.coupon_id;
				}
				setLoading(true);
				checkoutServices
					.getAnticipatedAmountCheckout(request)
					.then(({ data }) => {
						setCheckoutInformation(data);
						businessService
							.getContract(business.id)
							.then(({ data }) => {
								setContract(data);
								setLoading(false);
							})
							.catch((err) => {
								setLoading(false);
								if (err?.response?.status === 401) {
									localStorage.setItem("goBackRoute", "/checkout/" + business.id);
									props.history.push(`/login`, {
										goBackRoute: "/checkout/" + business.id,
										goBackRouteProps: props.location.state,
									});
								}
								dialog
									.withMessage(`${err?.response?.data?.message} Tente novamente!`)
									.withAction(() => window.location.reload())
									.show();
							});
					})
					.catch((err) => {
						setLoading(false);
						if (err?.response?.status === 401) {
							localStorage.setItem("goBackRoute", "/checkout/" + business.id);
							props.history.push(`/login`, {
								goBackRoute: "/checkout/" + business.id,
								goBackRouteProps: props.location.state,
							});
						}
						dialog
							.withMessage(`${err?.response?.data?.message} Tente novamente!`)
							.withAction(() => window.location.reload())
							.show();
					});
			}
		}
	}, []);

	const postBooking = async (is_offer_refused) => {
		let bookingData = {
			bookings: services.map((item) => ({
				business_id: recipientId,
				professional_id: item.professional_id,
				service_id: item.service_id,
				start_time: dayjs(item.dateStart).utc().format(),
				end_time: dayjs(item.dateStart).utc().add(item.professional.duration, "minute").format(),
				acesso: "web",
				answer: observation,
			})),
			is_cancellation_policy: checkoutInformation?.use_cancellation_policy,
			is_offer_refused: is_offer_refused ? true : false,
		};

		if (couponCheck) {
			bookingData.coupon_id = coupon?.coupon?.coupon_id;
		}

		const localRwgToken = localStorage.getItem("@prit-web/rwg_token");

		if (localRwgToken && !_.isNull(localRwgToken) && !_.isEmpty(localRwgToken)) {
			bookingData.bookings.forEach((booking) => {
				booking.rwg_token = localRwgToken;
			});
		}

		let emptyFields = [];
		let invalidTimeFields = [];

		bookingData.bookings.forEach((booking, index) => {
			Object.entries(booking).forEach(([key, value]) => {
				if (key !== "answer" && (value === undefined || value === "" || value === null)) {
					emptyFields.push(`Booking ${index + 1}: ${key}`);
				}
			});

			if (dayjs(booking.end_time).isBefore(booking.start_time)) {
				invalidTimeFields.push(`Booking ${index + 1}: end_time deve ser maior que start_time`);
			}
		});

		if (emptyFields.length > 0 || invalidTimeFields.length > 0) {
			let errorMessage = "";

			if (emptyFields.length > 0) {
				errorMessage += `Os seguintes campos estão vazios ou indefinidos:\n- ${emptyFields.join("\n- ")}\n`;
			}

			if (invalidTimeFields.length > 0) {
				errorMessage += `\nErros de tempo:\n- ${invalidTimeFields.join("\n- ")}`;
			}

			alert(errorMessage);
			return;
		}

		return bookingTransactionService.postBookingTransaction(bookingData);
	};

	const handleScheduleClickConfirm = async (is_offer_refused) => {
		setLoading(true);
		if (reschedule) {
			bookingTransactionService
				.putBookingUpdateSchedule({
					bookingId: reschedule.bookingId,
					data: {
						business_id: reschedule.businessId,
						professional_id: reschedule.professionalId,
						service_id: reschedule.serviceId,
						start_time: new Date(new Date(services[0]?.date).getTime()).toISOString(),
						end_time: new Date(
							new Date(services[0]?.date).getTime() + services[0]?.service?.serviceDuration * 60000
						).toISOString(),
						acesso: "web",
						answer: observation,
					},
				})
				.then((res) => {
					props.history.push(`/confirmation/${business?.id}`, {
						checkout: checkoutInformation,
						business: business,
						payload: props?.location?.state?.recipientId ? props.location.state : payload,
						couponValue: coupon,
						totalPricingType: totalPricingType,
					});
					setLoading(false);
				})
				.catch((err) => {
					let message = err?.response?.data?.message || err?.message || err;
					dialog.withMessage(`${message}, Tente novamente!`).show();
					setLoading(false);
				});
		} else {
			postBooking(is_offer_refused)
				.then((res) => {
					setLoading(false);
					const newWebScheduling = "Novo agendamento web";
					mixpanel.identify(business.phone ? business.phone : "", {
						my_custom_prop: "foo",
					});
					mixpanel.track(newWebScheduling, {
						my_custom_prop: "foo",
					});
					mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
						my_custom_prop: "foo",
					});

					props.history.push(`/confirmation/${business?.id}`, {
						checkout: checkoutInformation,
						business: business,
						payload: props?.location?.state?.recipientId ? props.location.state : payload,
						couponValue: coupon,
						totalPricingType: totalPricingType,
					});
					mixpanel.track("Agendamento confirmado-web", {
						my_custom_prop: "foo",
					});
					mixpanel.track(`Estabelecimento/web-${business?.name}`, {
						my_custom_prop: "foo",
					});
				})
				.catch((err) => {
					let message = err?.response?.data?.message;
					setLoading(false);
					dialog.withMessage(`${message} Tente novamente!`).show();
				});
		}
	};

	const handleObservation = (event) => {
		let text = event.target.value;
		if (text.length <= 300) {
			setObservation(text);
			setCharacterCount(text.length);
		}
	};

	useEffect(() => {
		if (cardData || pix) {
			dialog
				.withContent(DialogPayConfirm)
				.withProps({
					postPayment,
					cardData,
					pix,
					checkoutInformation,
					totalPricingType,
					currency,
					coupon,
					payload: props?.location?.state?.recipientId ? props.location.state : payload,
					postBooking,
					setPixCode,
					showUpdateInfo: () => setShowUpdateInfo(!showUpdateInfo),
					showError: (msg) => {
						setErrorMessage(msg);
						setShowError(true);
					},
				})
				.show();
		}
	}, [cardData, pix]);

	useEffect(() => {
		if (pixCode) {
			dialog
				.withContent(dialogPayCod)
				.withProps({
					pixCode,
					businessId: business.id,
				})
				.show();
		}
	}, [pixCode]);

	useEffect(() => {
		if (showUpdateInfo) {
			if (pix) {
				dialog.withContent(DialogPix).withProps({ setPix }).show();
			} else {
				dialog
					.withContent(DialogAddCard)
					.withProps({
						setCardData,
					})
					.show();
			}
			setShowUpdateInfo(false);
		}
	}, [showUpdateInfo]);

	useEffect(() => {
		if (showError) {
			dialog.withMessage(errorMessage).show();
		}
	}, [showError]);

	const provider = new GoogleAuthProvider();
	const auth = getAuth();

	const login = async () => {
		try {
			await signInWithPopup(auth, provider);
			
			const res = await checkoutServices.me();
	
			if (res?.data?.id) {
				localStorage.setItem("@prit-web/meData", JSON.stringify(res.data));
				localStorage.setItem("@prit-web/userId", res.data.id);
	
				if (!res.data.phoneUnverified) {
					props.history.replace("/login/personal-information-number", {
						logEventFor: 'Google',
						goBackRoute: "/checkout/" + business.id,
						goBackRouteProps: props.location.state,
					});
				} else {
					mixpanel.identify(res.data.phoneUnverified);
					mixpanel.track("web - Login - Google");
					window.location.reload();
				}
			}
			
		} catch (error) {
			alert(`Erro durante o login com Google: ${error.message}`);
		}
	};
	
	  
	const footer = () => {
		
		return (
			<Fragment>
				{!me?.name ? (
					<Typography style={{ padding: 20, fontSize: 14 }}>
						Para <strong>confirmar o agendamento</strong>, escolha uma das opções abaixo
					</Typography>
				) : (
					<div style={{ padding: 20 }}>
						{(checkoutInformation?.anticipation_payment > 0 ||
							checkoutInformation?.used_percentage_value ||
							checkoutInformation?.used_fixed_value) &&
							user &&
							!user.isAnonymous && (
								<Fragment>
									<Typography className={classes.policyWrapper}>
										{checkoutInformation?.value_cancellation_fee ? "Política de Cancelamento" : ""}
									</Typography>
									<Typography className={classes.policyWrapper}>
										{Utils.policyDetails(checkoutInformation, currency)}
									</Typography>
								</Fragment>
							)}
					</div>
				)}
				<div style={{ padding: "0 20px 20px 20px" }}>
					{checkoutInformation == null ? (
						<div></div>
					) : user &&
					  !user.isAnonymous &&
					me?.name &&
					  (postPayment ||
							(checkoutInformation?.anticipation_payment > 0 &&
								!checkoutInformation?.anticipation_payment_percentage &&
								!reschedule) ||
							(checkoutInformation?.anticipation_payment_percentage && offerAccepted)) ? (
						<div style={{ textAlign: "end" }}>
							{business?.currency !== "BRL" ? (
								<Grid>
									{!_.isNil(contract) && !_.isEmpty(contract) && !_.isNull(contract?.transaction_percentage) && (
										<Button
											onClick={() => {
												if (questions?.length > 0 && user && !user.isAnonymous && observation === "") {
													setErrorObservation(true);
													if (observationTextField && observationTextField.current) {
														observationTextField.current.focus();
													}
												} else {
													dialog.withContent(DialogPayConfirm).show();
												}
											}}
											style={{ width: "100%", float: "left", marginRight: "1px" }}
											className={classes.root}>
											{"Fazer pagamento"}
										</Button>
									)}
								</Grid>
							) : (
								<div style={{ justifyContent: "space-evenly", width: "100%", display: "flex" }}>
									{!_.isNull(contract?.transaction_percentage) && (
										<Button
											onClick={() => {
												if (questions?.length > 0 && user && !user.isAnonymous && observation === "") {
													setErrorObservation(true);
													if (observationTextField && observationTextField.current) {
														observationTextField.current.focus();
													}
												} else {
													setPix(null);
													setCardData(null);
													dialog
														.withContent(DialogAddCard)
														.withProps({
															setCardData,
														})
														.show();
												}
											}}
											style={{
												width: contract?.pix_transaction_percentage !== null ? "45%" : "100%",
												float: "left",
												marginRight: "1px",
											}}
											className={classes.root}>
											{t("Pay with card")}
										</Button>
									)}
									{!_.isNull(contract?.pix_transaction_percentage) && (
										<Button
											onClick={() => {
												if (questions?.length > 0 && observation === "") {
													setErrorObservation(true);
													if (observationTextField && observationTextField.current) {
														observationTextField.current.focus();
													}
												} else {
													setPix(null);
													setCardData(null);
													dialog.withContent(DialogPix).withProps({ setPix }).show();
												}
											}}
											style={{
												width: contract?.transaction_percentage !== null ? "45%" : "100%",
												marginRight: "1px",
											}}
											className={classes.root}>
											{"Pagar com pix"}
										</Button>
									)}
								</div>
							)}
						</div>
					) : (
						<div style={{ width: "100%", justifyContent: "center", display: "grid" }}>
							{ process.env.REACT_APP_GOOGLE_LOGIN_ENABLED === "true" && 
								!me?.name && 
								<Button
									disabled={false}
									className={classes.root}
									style={{
									textTransform: "uppercase",
									fontWeight: "Bold",
									marginBottom: "5%",
									}}
									onClick={() => login()}
								>
									<GoogleIcon style={{ position: 'absolute', left: 16 }} />
									<span style={{ flexGrow: 1, textAlign: 'center' }}>{t("Continue Google")}</span>
								</Button>
							}
							<Button
								disabled={false}
								onClick={() => {
									if (!me || !me?.name || (!me?.phone && !me?.phoneUnverified) || !user || user.isAnonymous) {
										localStorage.setItem("goBackRoute", "/checkout/" + business.id);
										props.history.push(`/login`, {
											goBackRoute: "/checkout/" + business.id,
											goBackRouteProps: props.location.state,
										});
										return;
									} else if (checkoutInformation?.anticipation_payment_percentage > 0) {
										dialog
											.withContent(DialogDiscount)
											.withProps({
												acceptOffer: () => {
													setOfferAccepted(true);
												},
												refuseOffer: (is_offer_refused) => {
													handleScheduleClickConfirm(is_offer_refused);
												},
												checkoutInformation,
												currency,
											})
											.show();
									} else {
										if (questions?.length > 0 && observation === "" && observationTextField.current) {
											setErrorObservation(true);
											if (observationTextField && observationTextField.current) {
												observationTextField.current.focus();
											}
										} else {
											handleScheduleClickConfirm();
										}
									}
								}}
								className={classes.root}
								style={{
									textTransform: "uppercase",
									fontWeight: "Bold",
									backgroundColor: me?.name ? '#440067' : '#fff',
									border: "2px solid #440067",
									color: me?.name ? '#fff' : "#440067"
								}}>								
								{!me?.name ? <PhoneIcon style={{ position: 'absolute', left: 16 }} /> : ''}
								{me?.name ? t("Confirm Scheduling") : t("Continue")}
							</Button>
						</div>
					)}
				</div>
			</Fragment>
		);
	};

	return (
		<>
			<TitleContainer handleClick={() => props.history.goBack()} footer={footer()} loading={loading}>
				<SEO business={ business } page="Checkout" />
				<div>
					{!postPayment && <ScheduleProgress progress={4}></ScheduleProgress>}
					{!postPayment && <div style={{ padding: 20 }} />}
					{!postPayment &&
						services.map((service, index) => {
							const professional = service.professional;
							const date = dayjs(service.date);
							return (
								<Fragment key={`checkout-${index}`}>
									<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
										<div style={{ display: "flex", alignItems: "center" }}>
											<div style={{ marginRight: "8px" }}>
												<Avatar className={classes.image} alt={business.name} src={business.thumbnailUrl} />
											</div>
											<div>
												<Typography className={classes.professionalTitle}>&nbsp;{business.name}</Typography>
											</div>
										</div>
										<div style={{ alignSelf: "center" }}>
											<Typography className={classes.bookingTitle}>
												{date.format("DD/MM")} {t("às")} {date.format("HH:mm")}
											</Typography>
										</div>
									</div>
									<div style={{ display: "flex", justifyContent: "space-between" }}>
										<div style={{ display: "flex", alignItems: "center" }}>
											<div style={{}}>
												<Typography className={classes.serviceTitle}>{service.service.serviceName}</Typography>
											</div>
											<div>
												<Typography className={classes.duration}>
													<img src={timeIcon} alt="Horário" />
													<span>{Utils.formatMinutes(service.professional.duration)}</span>
												</Typography>
											</div>
										</div>
										<div style={{ alignSelf: "center" }}>
											<Typography className={classes.price}>
												{Utils.getProfessionalPrice(professional, currency)}
											</Typography>
										</div>
									</div>
									<div style={{ paddingTop: 10, paddingBottom: 10 }}>
										<Divider />
									</div>
								</Fragment>
							);
						})}
					<div style={{ padding: "15px 0" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography className={classes.duration}>{t("Total services")}</Typography>
							<Typography className={classes.priceDetail}>
								{checkoutInformation &&
									Utils.getTotalPrice(checkoutInformation, services[0]?.professional?.pricingType, currency)}
							</Typography>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography className={classes.duration}>{t("Discount")}</Typography>
							<Typography className={classes.priceDetail}>
								{checkoutInformation &&
									Utils.getDiscountPrice(
										checkoutInformation,
										postPayment ? "Fixo" : services[0]?.professional?.pricingType,
										currency,
										coupon
									)}
							</Typography>
						</div>
					</div>
					<Divider />
					<div style={{ padding: "20px 0" }}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography className={classes.priceDetailBlue}>{t("Payment advance")}</Typography>
							<Typography className={classes.price}>
								{checkoutInformation &&
									Utils.getAntecipatedPrice(checkoutInformation, services[0]?.professional?.pricingType, currency)}
							</Typography>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography className={classes.priceDetailBlue}>
								{postPayment
									? "Saldo a pagar"
									: checkoutInformation &&
									  (checkoutInformation?.pricing_type ? "Saldo a pagar a partir de" : t("Balance after"))}
							</Typography>
							<Typography className={classes.price}>
								{postPayment
									? Utils.getPostPrice(postPayment.amount, currency)
									: Utils.getBalancePrice(checkoutInformation, totalPricingType, currency, coupon)}
							</Typography>
						</div>
					</div>
				</div>

				{user && !user.isAnonymous && !postPayment && questions?.length > 0 && (
					<div style={{ padding: "10px" }}>
						<div style={{ display: "flex" }}>
							<ChatIcon className={classes.observationIcon}></ChatIcon>
							<div style={{ width: "82%" }}>
								<Typography className={classes.question}>
									{questions[0]?.question?.split(" ").map((word) => {
										if (word.length >= 4 && word.substring(0, 4) == "http") {
											return (
												<a className={classes.questionLink} onClick={() => window.open(word)}>
													{word + " "}
												</a>
											);
										} else {
											return word + " ";
										}
									})}
								</Typography>
							</div>
							<Typography style={characterCount >= 300 ? { color: "red" } : {}} className={classes.characterCount}>
								{characterCount}/300
							</Typography>
						</div>
						<TextField
							inputRef={observationTextField}
							multiline
							id="outlined-basic"
							variant="outlined"
							className={classes.observation}
							value={observation}
							onChange={handleObservation}
							error={errorObservation}
							helperText={errorObservation ? "Resposta obrigatória!" : " "}
						/>
					</div>
				)}
			</TitleContainer>
			{/* <WhatsAppButton /> */}
		</>
	);
};

export default withRouter(Checkout);
